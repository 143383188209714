var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('CustomerNavbar')],1),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-heading dash-head"},[_vm._v("Ordered Information")]),_c('div',{staticClass:"panel-body"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',{staticClass:"text-left"},_vm._l((_vm.customerSummery.orders),function(row){return _c('tr',{key:row.id},[_c('td',[_vm._v(_vm._s(row.order_date))]),_c('td',[_vm._v(_vm._s(row.orderID))]),_c('td',[_vm._v(_vm._s(row.order_items[0].quantity))]),_c('td',[_vm._v(_vm._s(row.total_amount))]),_c('td',{class:row.order_status === 0
                      ? 'cancel'
                      : row.order_status === 1
                      ? 'pending'
                      : row.order_status === 2
                      ? 'confirm'
                      : row.order_status === 3
                      ? 'hold'
                      : row.order_status === 4
                      ? 'shipped'
                      : row.order_status === 5
                      ? 'delivered'
                      : row.order_status === 6
                      ? 'partial'
                      : ''},[_c('span',[_vm._v(" "+_vm._s(row.order_status === 0 ? "cancel" : row.order_status === 1 ? "Pending" : row.order_status === 2 ? "confirm" : row.order_status === 3 ? "Hold" : row.order_status === 4 ? "shipped" : row.order_status === 5 ? "delivered" : row.order_status === 6 ? "partial delivered" : "")+" ")])]),_c('td',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"btn btn-danger",attrs:{"disabled":row.order_status !== 1,"type":"button"},on:{"click":function($event){return _vm.cancelOrder(row.id)}}},[_vm._v(" Cancel ")]),_vm._v("   "),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.viewDetails(row.id)}}},[_vm._v(" View ")])])])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"text-center"},[_c('th',[_vm._v("Order Date")]),_c('th',[_vm._v("Order Id")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Status")]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v("Action")])])
}]

export { render, staticRenderFns }